import React from 'react'
import { Button, Icon, Header } from 'semantic-ui-react';

import TakeScreenshot from './TakeScreenshot';
import { PlayerConfig } from '../../api/player-api';

type State = {
    takeScreenshot: boolean
}

type Props = {
    cameraConfig: PlayerConfig
    onClose: () => void
}

export default class TakeScreenshotPrompt extends React.Component<Props, State> {

    state: State = {
        takeScreenshot: false
    }

    render() {
        return <div className="popup bottom-right">
            <Icon name="close" onClick={this.props.onClose} style={{cursor: "pointer", float: "right"}} />
            {this.state.takeScreenshot ?
            <TakeScreenshot cameraConfig={this.props.cameraConfig} /> : 
            <div style={{textAlign: "center"}}>
                <Header icon>
                    <Icon name="camera" />
                    Mozesz zrobić zdjęcie z tej kamery i udostępnić je znajomym.
                </Header>
                <div>
                    <Button primary onClick={() => this.setState({takeScreenshot: true})} size="large">
                        Zrób zdjęcie
                    </Button>
                </div>
            </div>}
        </div>
    }
}