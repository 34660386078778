import * as React from 'react'
import { IntlProvider } from 'react-intl'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { PlayerIdRoute } from './components/PlayerIdRoute';
import { configureLocale } from './configureLocale';

const { language, messages } = configureLocale()

const NotFound = () => <>
    Page was not found!
</>

export default () => {
    return (
    <IntlProvider locale={language} messages={messages}>
        <Router>
            <Switch>
                <Route path="/:playerPath" component={PlayerIdRoute} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    </IntlProvider>
    )
}