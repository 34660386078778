import * as React from 'react';
import './App.css';
import Root from './Root';


class App extends React.Component {
  render() {
    return (
      <Root />
    );
  }
}

export default App;
