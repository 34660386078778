import Axios from "axios"

export const getPlayerConfigByPath = async (id: string, username?: string, password?: string, token?: string) => {
    const auth = username && password ? { username, password } : undefined;
    const response = await Axios.get<PlayerConfig | DisabledPlayerConfig>(`/player-api/player-config/${id}`, {
        params: {
            token
        },
        auth
    });
    return response.data
}

export const playerLogout = async (playerPath: string) => {
    return await Axios.post(`/player-api/player-config/${playerPath}/logout`);
}

export const playerKeepalive = async (playerPath: string) => {
    return await Axios.post(`/player-api/player-config/${playerPath}/keepalive`);
}

export type Widget = {
    id?: string
} & AnyWidget

type AnyWidget = ImageWidget | LinkWidget | PopupWidget

type Position = "bottom-right" | "top-right" | "bottom-left" | "bottom-right"

export type ImageWidget = {
    type: "image"
    imageUrl: string
    hasLink: boolean
    linkUrl?: string
    linkTitle?: string
    position: Position
    imageSize: "tiny" | "small" | "medium"
}

export type LinkWidget = {
    type: "link"
    linkUrl: string
    linkTitle: string
}

export type PopupWidget = {
    type: "popup"
    text: string
}

export function isImageWidget(widget: AnyWidget): widget is ImageWidget {
    return widget.type === "image"
}

export function isPopupWidget(widget: AnyWidget): widget is PopupWidget {
    return widget.type === "popup"
}

export function isLinkWidget(widget: AnyWidget): widget is LinkWidget {
    return widget.type === "link"
}

type VideoFillMode = "black-strips" | "fill"

type PlayerSourceConfig = { name: string, streamUrl: string, progressControls: boolean, live: boolean, durationSeconds?: number }

export interface PlayerConfig {
    id: string
    title: string
    description?: string
    showHeader: boolean
    enabled: true
    thumbnailUrl: string
    disabledPlayerImageUrl?: string
    allowFullscreen: boolean
    autoplay: boolean
    hasSound: boolean
    videoFill: VideoFillMode
    locationEnabled: boolean
    locationName?: string
    latitude?: number
    longitude?: number
    widgets: Widget[]
    secured: boolean
    keepaliveIntervalSeconds?: number
    sessionId: string
    sources: PlayerSourceConfig[]
}

export type DisabledPlayerConfig = {
    id: string
    enabled: false
    description?: string
    title: string
    disabledPlayerImageUrl?: string
    plannedBroadcastDate?: string
}