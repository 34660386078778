import React, { useState } from 'react'
import { Container, Message, Form, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { PlayerSecurityType } from './PlayerLoader';

type AuthDetails = {user?: string, password?: string, token?: string};

type LoginProps = {
    onSubmit: (auth: AuthDetails) => void
    errorMessage?: string
    authType: PlayerSecurityType
}

export const LoginPage: React.SFC<LoginProps> = ({onSubmit, errorMessage, authType}) => {
    const [authDetails, setAuthDetails] = useState({} as AuthDetails);

    const submitForm = async () => {
        onSubmit(authDetails);
    }

    const TokenForm = () => {
        return <Form.Group widths="equal">
                    <Form.Field error={!!errorMessage} fluid="true">
                        <label>Token</label>
                        <Form.Input
                            autoFocus
                            type="text"
                            name="login"
                            required
                            value={authDetails.token}
                            onChange={(e) => setAuthDetails({token: e.target.value})}
                        />
                    </Form.Field>
            </Form.Group>
    }

    const UserPasswordForm = () => {
        return <Form.Group widths="equal">
                    <Form.Field error={!!errorMessage} fluid="true">
                        <label>Login</label>
                        <Form.Input
                            autoFocus
                            type="text"
                            name="login"
                            required
                            value={authDetails.user}
                            onChange={(e) => {
                                authDetails.user = e.target.value
                                setAuthDetails(authDetails)
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={!!errorMessage} fluid="true">
                        <label>Hasło</label>
                        <Form.Input
                            type="password"
                            name="password"
                            value={authDetails.password}
                            required
                            onChange={(e) => {
                                authDetails.password = e.target.value
                                setAuthDetails(authDetails)
                            }}
                        />
                    </Form.Field>
            </Form.Group>
    }

    return <>
    <FormattedMessage id="message.loginPage">
    {loginPage =>
    <Helmet>
        <title>LiveIP.pl - {loginPage}</title>
    </Helmet>}
    </FormattedMessage>
    <Container className="login-container">
        <Message info size="tiny" icon>
            <Icon name='lock' className="mobile-hide" />
            <Message.Content>
                <Message.Header>Zaloguj się</Message.Header>
                Dostęp do tego odtwarzacza jest ograniczony. Logowanie jest wymagane.
                <Form style={{paddingTop: ".75em"}} error={!!errorMessage}>
                    {authType === PlayerSecurityType.Password ?
                    <UserPasswordForm /> : <TokenForm />}
                {errorMessage && <Message error size="tiny">
                    <Message.Header>
                        <Icon name="exclamation circle" />
                        Logowanie się nie powiodło.
                    </Message.Header>
                    {errorMessage}
                </Message>}
                    <Button primary onClick={submitForm}>
                        <Icon name="sign in" className="hidden mobile-only" />
                        Zaloguj się
                    </Button>
                </Form>
            </Message.Content>
        </Message>
    </Container>
    </>
}
