import * as React from 'react'
import { Direction, Slider } from 'react-player-controls'

const BLUE = '#2185d0'

// A colored bar that will represent the current value
const SliderBar: React.SFC<any> = ({ value, style }) => (
  <div
    style={{
      position: 'absolute',
      background: BLUE,
      borderRadius: 2.5,
      display: 'inline-block',
      top: 0,
      bottom: 0,
      left: 0,
      width: `${value * 100}%`,
    ...style
    }}
  />
)

// A handle to indicate the current value
const SliderHandle: React.SFC<any> = ({ value, style }) => (
  <div
    style={{
      position: 'absolute',
      width: 12,
      height: 12,
      background: "white",
      borderRadius: '100%',
      top: 0,
      left: `${value*74}px `,
      marginTop: -3,
      marginLeft: -2,
        ...style}}
  />
)

export const VolumeSlider: React.SFC<{
  value: number,
  onVolumeChange: (value: number) => void
}> = ({ value, onVolumeChange }) => (
  <Slider
    isEnabled={true}
    direction={Direction.HORIZONTAL}
    onChange={onVolumeChange}
    style={{
        display: 'inline-block',
        margin: '0 0 0 .25em',
        width: 80,
      height: 6,
      borderRadius: 2,
      background: "#eeeeee80",
      transition: 'width 0.1s',
      cursor: 'pointer',
    }}
    className="mobile-hide"
  >
    <SliderBar value={value} />
    <SliderHandle value={value} />
  </Slider>
)