import * as React from 'react'
import { Icon } from 'semantic-ui-react';

import './PopupWindow.css'

export const PopupWindow: React.SFC<{
    onClose: () => void,
    popupText: string
}> = ({ onClose, popupText })=> {
    return (
    <div className="popup bottom-right">
        <Icon name="close" onClick={onClose} style={{cursor: "pointer"}} />
        {popupText}
    </div>
    )
}