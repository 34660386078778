import * as React from 'react'
import { Direction, Slider } from 'react-player-controls'

import './ProgressSlider.css'

const SliderBar: React.SFC<any> = ({ value, loading }) => (
  <div
    style={{
      width: `${(value > 1 ? 1: value) * 100}%`
    }}
    className={loading ? "progress-slider-bar progress-loading" : "progress-slider-bar"}
  />
)

const BufferredBar: React.SFC<any> = ({ value }) => (
  <div
    style={{
      width: `${(value > 1 ? 1: value) * 100}%`
    }}
    className={"progress-slider-buffered"}
  />
)

const SliderHandle: React.SFC<any> = ({ value, style, loading }) => (
  <div
    className={loading ? "progress-slider-handle progress-loading" : "progress-slider-handle"}
    style={{
      left: `calc(${value * 100}% - 7px)`,
      ...style
      }}
      
  />
)

export const ProgressSlider: React.SFC<{
  value: number,
  onProgressChange: (value: number) => void,
  loading: boolean,
  disabled: boolean,
  bufferedValue: number
}> = ({ value, onProgressChange, loading, disabled, bufferedValue }) => (
  <Slider
    isEnabled={!disabled}
    direction={Direction.HORIZONTAL}
    onChange={onProgressChange}
    className={disabled ? "progress-slider progress-disabled" : "progress-slider"}
  >
    {bufferedValue > 0 && <BufferredBar value={bufferedValue}  />}
    {value > 0 && <SliderBar value={value} loading={loading} />}
    <SliderHandle value={value} loading={loading} />
  </Slider>
)