import React from 'react'
import { DisabledPlayerConfig } from '../api/player-api';
import { Container, Icon, Message, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import './DisabledPlayer.css'
import moment from 'moment';

export const DisabledPlayer: React.FC<{config: DisabledPlayerConfig}> = ({config}) => {
    return <>
    <FormattedMessage id="message.disabledPlayerHeader">
    {disabledPlayer => 
    <Helmet>
        <title>
            {`${disabledPlayer}: ${config.title}`}
        </title>
    </Helmet>}
    </FormattedMessage>
    {config.disabledPlayerImageUrl && <div className="disabled-background">
        <img
            src={config.disabledPlayerImageUrl}
            alt=""
        />
    </div>}
    <div className="disabled-message-container">
            <Message className="disabled-message">
                <p><Icon name="power off"/><strong><FormattedMessage id="message.disabledPlayerHeader" /></strong></p>
                <FormattedMessage id="message.disabledPlayer" />
                {config.plannedBroadcastDate && <p>
                    <Label color="blue">
                        <Icon name="clock outline" />
                        <FormattedMessage id="message.plannedBroadcast" /> {moment(config.plannedBroadcastDate).format("LLL")}.
                    </Label>
                </p>}
            </Message>
    </div>
    </>
}
