import * as React from 'react';
import { useState } from 'react';

import { Button, Icon, Header, Image, Popup } from 'semantic-ui-react'
import ReactPlayer from 'react-player'

import screenfull from 'screenfull'
import classnames from 'classnames'

import { BottomStrip } from './bottom-strip/BottomStrip';
import WeatherComponent from './weather/WeatherComponent';
import TakeScreenshotPrompt from './screenshot/TakeScreenshotPrompt';
import { VolumeSlider } from './VolumeSlider';
import { PlayerSpinner } from './PlayerSpinner';
import { PopupWindow } from './popup/PopupWindow';
import { get } from 'lodash';
import { Socket }  from 'socket.io-client';
import { supports } from '../detectFeatures'
import { PlayerConfig, ImageWidget, isImageWidget, isPopupWidget, isLinkWidget } from '../api/player-api';

import './player.css'
import { ProgressSlider } from './ProgressSlider';

interface State {
    playing: boolean,
    muted: boolean,
    showPopup: boolean,
    showWeather: boolean,
    showScreenshot: boolean,
    currentPopup?: string,
    isFullscreen: boolean,
    playerPlaying: boolean,
    playerReady: boolean,
    playerInitiallyLoaded: boolean
    hasNews: boolean
    hasProgressControls: boolean
    volume: number
    /**
     * To be used when player is to be disabled after inactivity or long pause.
     */
    showPlayer: boolean
    fatalError?: { type: any, details: any}
    durationSeconds?: number
    playedSeconds: number
    bufferedSeconds?: number
    debugViewVisible: boolean
}

function displayImageWidgets(images: ImageWidget[]) {
    return images.map((w, index) => {
        const image = <Image key={index} src={w.imageUrl} className={`image-widget ${w.position} ${w.imageSize || "tiny"}`} />
        return w.type === "image" && w.hasLink ? <a key={index} href={w.linkUrl} title={w.linkTitle}>{image}</a> : image
    })
}

type PlayerAttributes = {
    poster: string,
    muted: boolean,
    autoPlay?: boolean
    /*
     * Intentionally misspelled property name.
     * Does not work on iOS when set to "playsInline".
     */
    playsinline?: ''
}

// let hidden: string | null = null;
// let visibilityChangeEventName: string | null = null;
// if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
//   hidden = 'hidden';
//   visibilityChangeEventName = 'visibilitychange';
  
// } else if (get(document, "msHidden")) {
//   hidden = 'msHidden';
//   visibilityChangeEventName = 'msvisibilitychange';
// } else if (get(document, "webkitHidden")) {
//   hidden = 'webkitHidden';
//   visibilityChangeEventName = 'webkitvisibilitychange';
// }

const LogoutButton = ({logout}: {logout: () => Promise<void>}) => {
    const [ loading, setLoading ] = useState(false);

    const callLogout = async () => {
        setLoading(true)
        try {
            await logout()
        } catch(err) {}
        setLoading(false)
    }

    return <>
        <Button
            floated="right"
            size="small"
            icon={true}
            inverted
            title="Wyloguj"
            loading={loading}
            onClick={callLogout}
        >
            <Icon name="sign out" />
            <span className="mobile-hide"> Wyloguj</span>
        </Button>
    </>
}

type Props = {
    config: PlayerConfig,
    socketIo?: typeof Socket,
    logout: () => Promise<void>
}


class PlayerComponent extends React.Component<Props, State> {
    state: State = {
        /*
         * Video has to be initially muted in order to have it auto play.
         */
        muted: !this.props.config.hasSound || this.props.config.autoplay,
        volume: (!this.props.config.hasSound || this.props.config.autoplay) ? 0 : 1,
        playing: this.props.config.autoplay,
        showPopup: false,
        isFullscreen: screenfull && screenfull.isFullscreen,
        showWeather: false,
        playerPlaying: false,
        playerReady: false,
        playerInitiallyLoaded: false,
        showScreenshot: false,
        hasNews: !!this.props.config.widgets.find(w => w.type === 'link') || this.props.config.locationEnabled,
        hasProgressControls: this.props.config.sources[0].progressControls,
        showPlayer: this.props.config.autoplay,
        debugViewVisible: false,
        playedSeconds: 0,
        durationSeconds: this.props.config.sources[0].durationSeconds
    }

    private playerWrapper = React.createRef<HTMLDivElement>()
    private playerOverlayWrapper = React.createRef<HTMLDivElement>()
    private playerRef = React.createRef<ReactPlayer>()

    // FIXME temporary christmas mode
    chrismasSong = (() => {
        if(this.props.config.title.includes("Szopka")) {
            console.log("Szopka")
            const random = Math.ceil(Math.random() * 3);
            const song = new Audio(`christmas${random}.mp3`);
            song.loop = true;
            song.volume = .6;
            return song;
        }
    })()
    private playPause = () => {
        this.setState({
            playing: !this.state.playing,
            showPlayer: true
        })
        if (this.chrismasSong) {
            if (this.state.playing) {
                this.chrismasSong.pause()
            } else {
                this.chrismasSong.play()
            }
        }
    }

    private onCloseScreenshot = () => this.setState({
        showScreenshot: false
    })

    private onClickPopup = (popuptext: string) => {
        this.setState({
            showPopup: !this.state.showPopup,
            showWeather: false,
            showScreenshot: false,
            currentPopup: popuptext
        })
    }

    private onClickScreenshot = () => {
        this.setState({
            showPopup: false,
            showWeather: false,
            showScreenshot: !this.state.showScreenshot
        })
    }

    private onClickWeather = () => this.setState({
        showWeather: !this.state.showWeather, 
        showPopup: false,
        showScreenshot: false
    })

    private onClickFullscreen = () => {
        if(screenfull && this.playerWrapper.current) {
            screenfull.toggle(this.playerWrapper.current)
        }
    }

    private initiateScreenfullCallback() {
        screenfull && screenfull.onchange(() => {
            if(screenfull && this.state.isFullscreen !== screenfull.isFullscreen) {
                this.setState({
                    isFullscreen: screenfull.isFullscreen
                })
            }
        })
    }

    componentDidMount() {
        this.initiateScreenfullCallback()

        document.addEventListener('keydown', this.handleDebugCode)
        // document.addEventListener(visibilityChangeEventName!!, () => {
        //     if(hidden)
        //         console.log("went out of focus", get(document, hidden))
        // }, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleDebugCode)
    }

    handleDebugCode = (e: KeyboardEvent) => {
        if (e.key === 'd') {
            this.setState({
                debugViewVisible: !this.state.debugViewVisible
            })
        }
    }

    handleErrorRecoveredEvent() {
        console.log("error recovered", this.state.fatalError)
        if(this.props.socketIo) {
            this.props.socketIo.emit("player_fatal_error_recovered", {
                ...this.state.fatalError
            });
        }
        this.setState({
            fatalError: undefined
        })
    }

    render() {
        const { config } = this.props
        const { widgets } =  config

        const playerAttributes: PlayerAttributes = { 
            poster: config.thumbnailUrl,
            muted: this.state.muted,
            autoPlay: config.autoplay || undefined
        }

        if (!config.allowFullscreen) {
            /**
             * On iOS when this attribute is not present video will play in
             * fullscreen. However system player is used, and our player
             * overlay is not shown.
             */
            playerAttributes.playsinline = ''
        }

        const loadingState = this.state.playing && !this.state.playerReady
        const videoWrapperClassName = classnames("video-wrapper", {
            "video-fit-cover": config.videoFill === "fill"
        })

        return (
        <div
            className={classnames('player-wrapper', {"has-news": this.state.hasNews, "has-progress": this.state.hasProgressControls})}
            ref={this.playerWrapper}
        >
            {this.state.debugViewVisible && this.renderDebugInformationWindow()}
            <div
                className={"overlay-wrapper"}
                ref={this.playerOverlayWrapper}
                onClick={e  => {
                    // On player area click: play/pause.
                    if(e.target === this.playerOverlayWrapper.current) {
                        this.playPause()
                    }
                }}
            >
                {this.props.config.showHeader && <header className="title">
                    <Header inverted>
                        <span>{config.title}
                        {this.props.config.description && <>{" "}
                            <Popup
                                trigger={<Icon name="info circle" size="small" />}
                                basic
                                inverted
                                position='bottom center'
                            >
                                {this.props.config.description}
                            </Popup>
                        </>}
                        </span>
                        {this.props.config.sources[0].live && this.state.playerPlaying && !loadingState && <Header.Subheader>
                            na żywo <Icon name="circle" color="red" size="small" />
                        </Header.Subheader>}
                    </Header>
                </header>}
                <div className="controls">
                    {this.renderPlayButton(loadingState)}
                    {config.hasSound && this.renderSoundControls()}
                    {(screenfull && screenfull.enabled && config.allowFullscreen) && this.renderFullscreenButton()}
                    {config.locationEnabled && config.latitude && config.longitude &&
                    <Button
                        icon={true}
                        floated="right"
                        size="small"
                        title={"Pogoda"}
                        onClick={this.onClickWeather}
                    >
                        <Icon name="cloud" color="blue" />
                        <span className="mobile-hide"> Pogoda</span>
                    </Button>}
                    {(this.props.config.sources[0].live && supports.SCREENSHOT && this.state.playerInitiallyLoaded) && <Button
                       floated="right"
                       size="small"
                       icon={true}
                       inverted
                       onClick={this.onClickScreenshot}
                    >
                        <Icon name="camera" />
                    </Button>}
                    {this.props.config.secured && <LogoutButton logout={this.props.logout} />}
                    {config.widgets
                        .filter(isPopupWidget)
                        .map((popup, index) => (
                            <Button
                                key={index}
                                icon={true}
                                floated="right"
                                size="small"
                                inverted
                                onClick={() => this.onClickPopup(popup.text)}
                            >
                                <Icon name="question" />
                            </Button>
                    ))}
                    {this.state.hasProgressControls && this.renderProgressControls(loadingState)}
                </div>
                {displayImageWidgets(widgets.filter(isImageWidget))}
                {this.state.showPopup && this.state.currentPopup && <PopupWindow
                    onClose={() => this.setState({showPopup: false})}
                    popupText={this.state.currentPopup}
                />
                }
                {this.state.showScreenshot &&
                    this.state.playerInitiallyLoaded &&
                    <TakeScreenshotPrompt
                        onClose={this.onCloseScreenshot}
                        cameraConfig={config}
                    />}
                {this.state.showWeather &&
                    config.latitude &&
                    config.longitude  &&
                <WeatherComponent
                    latitude={config.latitude}
                    longitude={config.longitude}
                />}
            </div>
            {this.state.hasNews && <BottomStrip
                locationEnabled={config.locationEnabled}
                location={config.locationName}
                latitude={config.latitude}
                longitude={config.longitude}
                links={widgets.filter(isLinkWidget)}
            />}
            <div
                className={videoWrapperClassName}
            >
                {!this.state.playing && this.renderCenteredPlayButton()}
                {loadingState && <PlayerSpinner text={"Ładowanie obrazu..."} />}
                {!this.state.showPlayer && <img
                    className="player-replacement-thumbnail"
                    src={config.thumbnailUrl}
                    alt=""
                />}
                {this.state.showPlayer && <ReactPlayer
                    ref={this.playerRef}
                    url={config.sources[0].streamUrl}
                    width="100%"
                    height="100%"
                    playing={this.state.playing}
                    volume={this.state.volume}
                    config={{
                        file: { 
                            attributes: playerAttributes
                        }
                    }}
                    onStart={() => {
                        console.log("on start")
                    }}
                    onPlay={() => {
                        console.log("on play")
                        this.setState({
                            playerPlaying: true
                        })
                    }}
                    onPlaying={() => {
                        console.log("on playing")
                        this.setState({
                            playerPlaying: true,
                            playerReady: true
                        })
                        if(this.state.fatalError) {
                            this.handleErrorRecoveredEvent()
                        }
                    }}
                    onCanPlay={() => {
                        console.log("on canplay")
                        this.setState({
                            playerInitiallyLoaded: true
                        })
                        if(this.state.fatalError) {
                            this.handleErrorRecoveredEvent()
                        }
                    }
                    }
                    onError={((err: any, errorDetails: any) => {
                        /**
                         * TODO: This must be verified on iOS devices.
                         *
                         * Errors occur when buffering, when not fatal, playing can continue as usual.
                         * Playing will continue until buffered video ends.
                         */
                        // TODO handle potential 401 here
                        // const errorCode = get(errorDetails, 'networkDetails.status')
                        // if(errorCode === 401) {
                        //     console.error("Unauthorized")
                        // }
                        if(get(errorDetails, 'fatal')) {
                            // TODO Errors marked as fatal might actually be recoverable.
                            // TODO How to determine if fatal error ocurred?
                            const { type, details } = errorDetails
                            console.error("Fatal streaming error.")
                            if(this.props.socketIo) {
                                // report just once if not recovered
                                if (!this.state.fatalError) {
                                    // Provide some feedback: problem ocurred
                                    this.props.socketIo.emit("player_fatal_error", {
                                        type,
                                        details,
                                        playerInitiallyLoaded: this.state.playerInitiallyLoaded
                                    });
                                }
                            }
                            this.setState({
                                fatalError: {
                                    type, details
                                }
                            })
                        }
                    }) as any}
                    onEnded={() => {
                        console.log("on ended")
                        this.setState({
                            playerPlaying: false
                        })
                    }}
                    onPause={() => {
                        console.log("on pause")
                        this.setState({
                            playerPlaying: false
                        })
                    }}
                    onReady={() => {
                        console.log("on ready")
                        this.setState({
                            playerReady: true
                        })
                    }}
                    onWaiting={() => {
                        console.log("on waiting")
                        if(this.props.socketIo) {
                            // TODO should not be reported after video has been paused for a while and
                            // has to catch up to the stream.
                            // Provide some feedback: video is lagging.
                            if(!this.state.fatalError && this.state.playerPlaying && this.state.playerReady) {
                                this.props.socketIo.emit("player_waiting", "waiting for stream");
                            }
                        }
                        this.setState({
                            playerReady: false,
                            playerPlaying: false
                        })
                    }}
                    onProgress={(state) => {
                        this.setState({
                            playedSeconds: state.playedSeconds,
                            bufferedSeconds: state.loadedSeconds
                        })
                        if(this.state.durationSeconds && state.loadedSeconds > this.state.durationSeconds) {
                            this.setState({
                                durationSeconds: state.loadedSeconds
                            })
                        }
                    }}
                    onDuration={(duration) => {
                        this.setState({
                            durationSeconds: duration
                        })
                    }}
                />}
            </div>
        </div>
        )
    }

    renderDebugInformationWindow = () => {
        const bufferHealth = (this.state.playerReady && this.state.bufferedSeconds && this.state.playedSeconds
            && this.state.bufferedSeconds > this.state.playedSeconds) ?
        this.state.bufferedSeconds - this.state.playedSeconds :
        undefined;
        function displaySeconds(seconds?: number) {
            if (seconds !== undefined) {
                return `${Math.round(seconds * 10)/10}s`
            } else {
                return "none"
            }
        }
        return <div className="debug-box">
            <div className="close-icon" onClick={() => this.setState({debugViewVisible: false})}>[x]</div>
            <ul>
                <li>ID: <strong className="value">{this.props.config.id}</strong></li>
                <li>Ready: <strong className="value">{this.state.playerReady ? "Yes": "No"}</strong></li>
                <li>Played: <strong className="value">{displaySeconds(this.state.playedSeconds)}</strong></li>
                <li>Duration: <strong className="value">{displaySeconds(this.state.durationSeconds)}</strong></li>
                <li>Buffer health: <strong className="value">{displaySeconds(bufferHealth)}</strong></li>
            </ul>
        </div>
    }

    renderProgressControls = (loadingState: boolean) => {
        const buffered = (this.state.bufferedSeconds && this.state.durationSeconds) ?
        this.state.bufferedSeconds / this.state.durationSeconds
        : 0
        return <div>
            <div className="progress-controls">
            {(this.state.durationSeconds) &&
            <span className="duration-text mobile-hide">
                {secondsToHms(this.state.playedSeconds)}
                {" / "}
                {secondsToHms(this.state.durationSeconds)}
            </span>}
            <ProgressSlider
                loading={loadingState}
                value={this.state.durationSeconds ? (this.state.playedSeconds || 0) / this.state.durationSeconds : 0}
                onProgressChange={(value) => {
                    console.log("Progress value " + value )
                    const current = this.playerRef.current
                    if (current && value > 0) {
                        current.seekTo(value)
                    }
                }}
                disabled={!this.state.playerInitiallyLoaded}
                bufferedValue={buffered}
             />
             </div>
        </div>
    }
    renderCenteredPlayButton = () => <div className="centered-button" >
        <Button icon="play" color="blue" circular size="massive" onClick={this.playPause}/>
    </div>

    renderPlayButton = (loadingState: boolean) => {
        
        return <Button icon={true} onClick={this.playPause} color="blue" size="small" floated="left">
            {loadingState ?
            <Icon name="spinner" loading /> :
            <>
                {this.state.playerPlaying ? <Icon name="pause" /> : <Icon name="play" />}
            </>}
        </Button>
    }

    renderSoundControls = () => {
        const { muted, volume } = this.state
        return <div style={{float: 'left', marginLeft: '.25em'}}>
        <Button
            icon={true}
            onClick={this.onClickSoundControl}
            color="blue"
            size="small"
        >
        {muted ? 
        <Icon name="volume off"  /> :
        volume > .5 ? <Icon name="volume up" /> : <Icon name="volume down" />
        }
    </Button>
    <VolumeSlider
        value={muted ? 0 : volume}
        onVolumeChange={this.onVolumeChange}
    />
    </div>
    }

    onVolumeChange = (value: number) => {
        this.setState({
            volume: value,
            muted: value === 0
        })
    }

    /**
     * When un-muting turn the volume up
     * a little if it was set to very low value.
     */
    onClickSoundControl = () => {
        const INITIAL_LOWEST_SOUND = .2;
        const { muted, volume} = this.state
        this.setState({
            muted: !muted,
            volume: muted ?
                volume < INITIAL_LOWEST_SOUND ? INITIAL_LOWEST_SOUND : volume 
                : volume
        })
    }

    renderFullscreenButton = () => {
        return (
        <Button
            icon={true}
            onClick={this.onClickFullscreen}
            color="blue"
            size="small"
            floated="right"
        >
            {this.state.isFullscreen ? 
            <Icon name="compress" /> :
            <Icon name="expand" />}
        </Button>
        )
    }

}

export default PlayerComponent

function secondsToHms(d: number): string {
    let h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
}