import * as React from 'react'
import { SegmentGroup, Label, Segment } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { WeatherInfoResponse, ForecastApiResponse, Forecast, getWeatherInfo } from '../../api/weather-api';
import { CurrentWeatherConditons } from './CurrentWeatherConditions';

import './weather.css'
import 'weather-icons/css/weather-icons.min.css'


const NUMBER_OF_FORECASTST = 6

type State = {
    currentWeather?: WeatherInfoResponse
    forecast?: ForecastApiResponse,
    sunriseHour: number
    sunsetHour: number
}


class WeatherComponent extends React.Component<{latitude: number, longitude: number}, State> {

    state: State = {
        sunriseHour: 6,
        sunsetHour: 21
    }

    async componentDidMount() {
        const { latitude, longitude } = this.props

        const weatherInfo = await getWeatherInfo(latitude, longitude)

        this.setState({
            ...weatherInfo
        })
    }

    render() {
        const {forecast, currentWeather } = this.state
        console.log(currentWeather)
        return (
        <div className="weather-popup bottom-right">
        {(forecast && currentWeather) && <>
        <SegmentGroup horizontal>
            {forecast.list.slice(0, NUMBER_OF_FORECASTST).map((w, idx) => {
                const time = moment(w.dt * 1000)
                const isDay =  time.hour() >= this.state.sunriseHour && time.hour() <= this.state.sunsetHour
                return <Segment key={idx} textAlign="center">
                    <Label circular className="weather-temperature" color="blue">
                        {Math.floor(w.main.temp)} °C
                    </Label>
                    <div className="weather-time"> 
                        {time.calendar()}
                    </div>
                    <div className="weather-icon" title={w.weather[0].description}>
                        <i className={"wi " + mapWeatherToIcon(w, isDay ? "day" : "night")} />
                    </div>
                </Segment>
        })}
        </SegmentGroup>
        <CurrentWeatherConditons
            cityName={forecast.city.name}
            time={moment().tz("Europe/Warsaw").format('LT')}
            cloudsPercent={currentWeather.clouds.all}
            windSpeedMetersPerS={forecast.list[0].wind.speed}
        />
        </>}
        </div>
        )
    }
}


function mapWeatherToIcon(weather: Forecast, timeOfDay: "day" | "night") {
    const { id } = weather.weather[0]

    if (id >= 200 && id < 300) {
        // thunderstorm
        return timeOfDay === "day" ? "wi-day-lightning" : "wi-night-lightning"
    }

    if (id >= 300 && id < 400) {
        // drizzle
        return timeOfDay === "day" ? "wi-day-showers" : "wi-night-showers"
    }

    if (id >= 500 && id < 600) {
        // rain
        return timeOfDay === "day" ? "wi-day-showers" : "wi-night-alt-showers"
    }

    if (id >= 600 && id < 700) {
        // snow
        return timeOfDay === "day" ? "wi-day-snow" : "wi-night-alt-snow"
    }

    if (id >= 700 && id < 800) {
        // Atmosphere
        return timeOfDay === "day" ? "wi-day-fog" : "wi-night-fog"
    }

    if ((id >= 801 && id < 900) || weather.clouds.all > 40) {
        // clouds
        return timeOfDay === "day" ? "wi-day-cloudy" : "wi-night-alt-cloudy"
    }

    if(id === 800) {
        // clear
        return timeOfDay === "day" ? "wi-day-sunny" : "wi-night-clear"
    }
}

export default WeatherComponent