import React from 'react'
import { Divider } from 'semantic-ui-react';
import './PlayerSpinner.css'

export const PlayerSpinner: React.SFC<{text?: React.ReactNode}> = ({text}) => {
    return (
        <div className="centered-button">
        <div style={{textAlign: "center"}} >
            {/* Pure html spinner below: https://loading.io/css/ */}
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        {text &&<>
            <Divider hidden/>
            <p className="loading-text">{text}</p>
        </>}
    </div>
    )
}