import * as React from 'react'
import Helmet from 'react-helmet'

import PlayerComponent from './Player'
import { PlayerConfig, getPlayerConfigByPath, DisabledPlayerConfig } from '../api/player-api'
import { PlayerSpinner } from './PlayerSpinner'
import { FormattedMessage } from 'react-intl';
import { Message, Container, Icon } from 'semantic-ui-react';
import { DisabledPlayer } from './DisabledPlayer';
import set from 'lodash/set';
import querystring from 'querystring';

import io, { Socket }  from 'socket.io-client';
import { LoginPage } from './LoginPage'

import { playerLogout, playerKeepalive } from '../api/player-api'

export enum PlayerSecurityType {
    Password = "password",
    Token = "token",
    None = "none"
}

interface State {
    loading: boolean
    error: boolean
    notFound: boolean
    authType: PlayerSecurityType
    incorrectAuth: boolean
    accessRefused: boolean
    playerConfig?: PlayerConfig | DisabledPlayerConfig
    socketIo?: typeof Socket
}

type Props = { playerPath: string}

class PlayerLoader extends React.Component<Props, State> {
    state: State = {
        error: false,
        loading: true,
        notFound: false,
        authType: PlayerSecurityType.None,
        incorrectAuth: false,
        accessRefused: false
    }

    sessionKeepalive = async () => {
        if (!document.hidden) {
            console.log("Tab active, Session keepalive running....")
            try {
                await playerKeepalive(this.props.playerPath)
            } catch (err) {
                console.error("Keepalive failed", err)
            }
         } else { 
            console.log("Tab inactive");
        }
    }

    private keepaliveInterval?: number

    async reloadPlayer(user?: string, password?: string, token?: string) {
        const { playerPath } = this.props
        this.setState({
            error: false,
            loading: true,
            incorrectAuth: false,
            notFound: false,
            accessRefused: false
        })
        try {
            const playerConfig: PlayerConfig | DisabledPlayerConfig = await getPlayerConfigByPath(playerPath, user, password, token)
            if (playerConfig.enabled && playerConfig.secured && playerConfig.keepaliveIntervalSeconds) {
                this.keepaliveInterval = window.setInterval(this.sessionKeepalive, playerConfig.keepaliveIntervalSeconds * 1000);
            } else {
                clearInterval(this.keepaliveInterval)
            }
            this.setState({
                playerConfig,
                error: false,
                loading: false,
                // not really
                authType: PlayerSecurityType.None,
                incorrectAuth: false
            })
            return playerConfig
        } catch(err) {
            if (err && err.response && err.response.status === 404) {
                this.setState({
                    notFound: true,
                    loading: false
                })
            } else if (err && err.response && err.response.status === 401) {
                const authType = err.response.data.authType || PlayerSecurityType.None;
                this.setState({
                    authType: authType,
                    loading: false,
                    // If previous was 'None' then it's first login.
                    incorrectAuth: this.state.authType !==  PlayerSecurityType.None
                })
            } else if (err && err.response && err.response.status === 403 && this.state.authType !==  PlayerSecurityType.None) {
                const authType = err.response.data.authType || PlayerSecurityType.None;
                this.setState({
                    // Must be set to display login page again.
                    authType,
                    loading: false,
                    accessRefused: true
                })
            } else {
                this.setState({
                    error: true,
                    loading: false
                })
            }
            clearInterval(this.keepaliveInterval)
            throw err
        }
    }

    async reloadPlayerAndInitializeSocketIo({ user, password, token: tokenFromForm }: { user?: string, password?: string, token?: string} = {}) {
        let playerConfig: PlayerConfig | DisabledPlayerConfig;
        try {
            var query = querystring.parse(window.location.search.substring(1));
            const token = tokenFromForm || (Array.isArray(query.token) ? undefined : query.token);
            playerConfig = await this.reloadPlayer(user, password, token);
        } catch(err) {
            return;
        }
        try {
            const client = io({
                path: "/player-api/socket.io",
                query: {
                    playerPath: this.props.playerPath,
                    sessionId: (playerConfig.enabled && playerConfig.sessionId) || undefined
                }
            })
            this.setState({
                socketIo: client
            })
            client.on(`player-disabled/${playerConfig.id}`, () => {
                console.log("Received [player-disabled] message, disabling the player.")
                set(playerConfig, 'enabled', false)
                set(playerConfig, 'plannedBroadcastDate', undefined)
                this.setState({
                    playerConfig
                })
            });
            client.on(`player-enabled/${playerConfig.id}`, async () => {
                console.log("Received [player-enabled] message, enabling the player.")
                try {
                    await this.reloadPlayer();
                } catch(er) {}
            });
            client.on(`logout`, async () => {
                console.log("Received [logout] message, reloading the player.")
                try {
                    await this.reloadPlayer();
                } catch(er) {}
            });
        } catch(err) {
            // Not critical, safely ignore the error.
            console.error(err)
        }
    }

    async componentDidMount() {
        this.reloadPlayerAndInitializeSocketIo();
    }

    logout = async () => {
        await playerLogout(this.props.playerPath)
    }

    loadingPage() {
        return (<FormattedMessage id="message.loadingPlayerConfiguration">
        {loadingText => <PlayerSpinner text={loadingText} />}
        </FormattedMessage>)
    }

    errorPage() {
        return <FormattedMessage id="message.loadingErrorHeader">
        {header => <>
        <Container className="error-container">
            <Message error >
                <p><Icon name="times circle"/><strong>{header}</strong></p>
                <FormattedMessage id="message.loadingError" />
            </Message>
        </Container>
        </>}
        </FormattedMessage>
    }

    notFoundPage() {
        return <FormattedMessage id="message.configurationNotFoundHeader">
        {header =>
        <>
        <Container className="error-container">
            <Message info>
                <p><Icon name="info circle"/><strong>{header}</strong></p>
                <FormattedMessage id="message.configurationNotFound" />
            </Message>
        </Container>
        </>
        }
        </FormattedMessage>
    }

    render() {
        const { playerConfig, loading, error, notFound } = this.state
        if (loading) {
            return this.loadingPage()
        }

        if (notFound) {
            return this.notFoundPage()
        }

        if (this.state.authType !== PlayerSecurityType.None) {
            const errorMessage = this.state.incorrectAuth ?
                "Niepoprawne dane dostępowe. Spróbuj ponownie."
                : this.state.accessRefused ?
                "Wykryto podwójne logowanie. Aby skorzystać z tych danych autoryzacyjnych ponownie, należy wylogować się w dotychczas wykorzystywanym miejscu."
                : undefined;
            return <>
                <LoginPage
                    onSubmit={({user, password, token}) => {
                        this.reloadPlayerAndInitializeSocketIo({user, password, token});
                    }}
                    errorMessage={errorMessage}
                    authType={this.state.authType}
                />
            </>
        }

        if (error || !playerConfig) {
            return this.errorPage()
        }

        if (!playerConfig.enabled) {
            return <DisabledPlayer config={playerConfig} />
        }

        return <>
            <Helmet>
                <title>{playerConfig.title}</title>
                {playerConfig.description && <meta name="description" content={playerConfig.description} />}
            </Helmet>
            <PlayerComponent
                config={playerConfig}
                socketIo={this.state.socketIo}
                logout={this.logout}
            />
        </>
    }
}

export default PlayerLoader