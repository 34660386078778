const SCREENSHOT_QUALITY = .4

/**
 * Modified function from:
 * https://github.com/feross/capture-frame
 */
export function captureFrame (video: string | HTMLVideoElement) {
    if (typeof video === 'string') {
      const possiblyVideo =  document.querySelector(video)
      if(possiblyVideo === null || possiblyVideo.nodeName !== 'VIDEO') {
          throw Error('Video element was not found')
      }
      video = possiblyVideo as HTMLVideoElement
    }
  
    if (video == null || video.nodeName !== 'VIDEO') {
      throw new TypeError('First argument must be a <video> element or selector')
    }
  
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
  
    const context2d = canvas.getContext('2d')

    if(context2d === null) {
        throw Error("Context2d not supported.")
    }

    context2d.drawImage(video, 0, 0)
  
    const dataUri = canvas.toDataURL('image/jpeg', SCREENSHOT_QUALITY)
    const data = dataUri.split(',')[1]
  
    return Buffer.from(data, 'base64')
}