import * as React from 'react'
import { Label, Icon } from 'semantic-ui-react';
import Marquee3k from 'marquee3000';
import { LinkWidget } from '../../api/player-api';

import './BottomStrip.css'

type BottomStripProps = {
    links: LinkWidget[]
    locationEnabled: boolean
    location?: string
    latitude?: number
    longitude?: number
}

export class BottomStrip extends React.Component<BottomStripProps, {}> {
    render() {
        const {locationEnabled, location, links, latitude, longitude} = this.props
        return (
            <div className="bottom-stip">
                {locationEnabled && <LocationLabel locationName={location} latitude={latitude} longitude={longitude} />}
                {links.length > 0 && <MarqueeLinks links={links} />}
            </div>
        )
    }
}

const LocationLabel: React.SFC<{
    locationName?: string,
    latitude?: number,
    longitude?: number
}> = ({locationName, latitude, longitude}) => {
    const link = (latitude && longitude) && `https://www.google.com/maps/?q=${latitude},${longitude}`
    return <div className="camera-location">
        <Label
            ribbon as="a"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon name="globe" />
            {locationName && <span className="mobile-hide">{locationName}</span>}
        </Label>
    </div>
}

type MarqueeLinksProps = {
    links: LinkWidget[]
}

class MarqueeLinks extends React.Component<MarqueeLinksProps, {}> {
    initializeMarquee() {
        Marquee3k.init()
    }

    componentDidMount() {
        // TODO: marquee size should update on window resize event.
        // INFO: Marquee3k.refreshAll method does not work.
        this.initializeMarquee()
    }

    render() {
        const { links } = this.props
        return (
        <div className="marquee3k" data-pausable={true} data-speed="0.35">
            <span>
            {links.map((link, index) => {
                return <span key={index}>
                    <a
                        href={link.linkUrl}
                        title={link.linkTitle}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {link.linkTitle}
                    </a>
                    <Icon name="asterisk" className="separator" size="small" />
                </span>
            })}
            </span>
        </div>
        )
    }
}