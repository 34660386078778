import React from 'react'
import { Button, Icon, Image, Grid, Message, Segment } from 'semantic-ui-react';

import {captureFrame} from '../../utils/captureFrame'
import Axios from 'axios';
import { PlayerConfig } from '../../api/player-api';

type State = {
    imageBuffer?: string,
    uploadedImageUrl?: string
    error: boolean
}

type Props = {
    cameraConfig: PlayerConfig
}

const createScreenshotBlob = async () => {
    const buf = captureFrame('video')
        
    const blob = new window.Blob([buf], {
        type: "image/jpeg"
    })
    return blob
}

export default class TakeScreenshot extends React.Component<Props, State> {
    
    state: State = {
        error: false
    }

    async componentDidMount() {
        
        try {
            const blob = await createScreenshotBlob()
            const data = new FormData() 
            data.append('image', blob, "image")

            Axios.post<{fileUrl: string}>("/player-api/userupload/" + this.props.cameraConfig.id, data)
            .then((response) => {
                this.setState({
                    uploadedImageUrl: response.data.fileUrl
                })
            }).catch(err => {
                this.setState({
                    error: true
                })
            })
            const bufferDataString = window.URL.createObjectURL(blob)
        
            this.setState({
                imageBuffer: bufferDataString
            })
        } catch(err) {
            this.setState({
                error: true
            })
        }

    }

    render() {
        return <>
            {this.state.error && <>
                <Message error>
                    Wystąpił błąd podczas robienia zdjęcia.
                </Message>
            </>}
            {
                (!this.state.error &&!this.state.imageBuffer) && <>
                    Tworzenie zdjęcia z kamery...
                    <Icon loading name="spinner" />
                </>
            }
            {(!this.state.error && this.state.imageBuffer && !this.state.uploadedImageUrl) && <>
                    Wgrywanie zdjęcia z kamery...
                    <Icon loading name="spinner" />
                </>
            }
            {this.state.uploadedImageUrl &&
            <Grid>
                <Grid.Column width="8">
                    <p>
                        Zrobiłeś zdjęcie z kamery, udostępnij je znajomym:
                    </p>
                    <Button
                        color="facebook"
                        size="small"
                        compact
                        as="a"
                        href={`https://facebook.com/sharer.php?u=${this.state.uploadedImageUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Icon name="facebook" />
                        Facebook
                    </Button>
                </Grid.Column>
                <Grid.Column width="8">
                    <Segment raised compact>
                    <a href={this.state.uploadedImageUrl} target="_blank" rel="noopener noreferrer">
                        <Image src={this.state.uploadedImageUrl} size="small"/>
                    </a>
                    </Segment>
                </Grid.Column>
            </Grid>
            }
        </>
    }
}