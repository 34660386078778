import axios from 'axios'
import moment from 'moment'

const buildForecastURL = (lat: number, lon: number) =>
    `/weather-api/forecast?units=metric&lat=${lat}&lon=${lon}`

const buildWeatherURL = (lat: number, lon: number) =>
    `/weather-api/weather?units=metric&lat=${lat}&lon=${lon}`

const getWeather = (latitude: number, longitude: number) => axios.get<WeatherInfoResponse>(buildWeatherURL(latitude, longitude))
const getForecast = (latitude: number, longitude: number) => axios.get<ForecastApiResponse>(buildForecastURL(latitude, longitude))

type CombinedWeatherInfo = {
    currentWeather?: WeatherInfoResponse
    forecast?: ForecastApiResponse,
    sunriseHour: number
    sunsetHour: number
}

export const getWeatherInfo = async (latitude: number, longitude: number) => {
    const weatherRequest = getWeather(latitude, longitude)
    const forecastRequest = getForecast(latitude, longitude)

    const forecastResponse = await forecastRequest
    const weatherResponse = await weatherRequest

    const weatherInfo: CombinedWeatherInfo = {
        forecast: forecastResponse.data,
        currentWeather: weatherResponse.data,
        sunriseHour: moment(weatherResponse.data.sys.sunrise * 1000).hour(),
        sunsetHour: moment(weatherResponse.data.sys.sunset * 1000).hour()
    }
    return weatherInfo
}

export interface WeatherInfoResponse {
    sys: {
        sunset: number,
        sunrise: number
    },
    main: {
        temp: number,
        humidity: number
    },
    wind: {
        deg: number,
        speed: number
    },
    clouds: {
        all: number
    }
    weather: Weather[]
}

export interface Forecast {
    dt: number,
    main: {
        temp: number
        humidity: number
    },
    clouds: {
        all: number
    }
    snow: {
        "3h": number
    },
    rain: {
        "3h": number
    },
    wind: {
        speed: number
    }
    weather: Weather[]
}
interface Weather {
    description: string
    id: number,
    icon: string
}

export interface ForecastApiResponse {
    city: {
        name: string
    }
    list: Forecast[]
}