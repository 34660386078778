import * as React from 'react'
import { Label } from 'semantic-ui-react';

type Props = {
    cityName: string,time: string, cloudsPercent: number, windSpeedMetersPerS: number
}

export const CurrentWeatherConditons: React.SFC<Props> = ({
    cityName,
    time,
    cloudsPercent,
    windSpeedMetersPerS
}) => {
    console.log(cloudsPercent)
return <Label.Group style={{float: "right"}}>
    <Label>
        {/** Timezone should be derived from location data. */}
        {cityName}, {time}
    </Label>
    {cloudsPercent > 0 && <Label>
        <i className="wi wi-cloud" />{" "}
        {cloudsPercent} %
    </Label>}
    {windSpeedMetersPerS > 0 && <Label>
        <i className="wi wi-strong-wind" />{" "}
        {windSpeedMetersPerS} m/s
    </Label>}
</Label.Group>
}